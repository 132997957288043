<template>
    <div class="userFans clearfix">
        <template v-if="list.length>0">
            <div class="fl userFans_item" v-for="(item,index) in list" :key="index">
                <div class="userFans_top">
                    <img class="userFans_top_head" :src="item.thumb" alt=""  @click="getOtherUser(item.id)">
                    <div class="userFans_top_name"  @click="getOtherUser(item.id)">{{item.username}}</div>
                    <div class="userFans_top_dec">{{item.city}}丨{{item.autograph}}</div>
                    <div class="userFans_top_fans">人气 {{item.mood}}&nbsp;&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;&nbsp;粉丝 {{item.fans_num}}</div>
                    <div class="userFans_top_button">
                        <div class="userFans_top_button_home" @click="getOtherUser(item.id)">个人主页</div>
                        <div class="userFans_top_button_Focus" v-if="is_benren == 1" @click="followFun(index,item.id)" :class="item.is_hg == 1?'active':''">
                            <img v-if="item.is_hg == 0" src="../../assets/images/dailyguanzhu.png" alt="">
                            <img v-if="item.is_hg == 1" src="../../assets/images/guanzhu02@2x.png" alt="">
                            {{item.is_hg == 1? '已关注':'关注'}}
                        </div>
                    </div>
                </div>
                <div class="userFans_update">
                    <div class="userFans_update_title">最近更新</div>
                    <div class="userFans_update_item clearfix" v-if="item.work[0]">
                        <div class="fl userFans_update_item_img">
                            <img :src="item.work[0].thumb" alt="">
                        </div>
                        <div class="fl userFans_update_item_info">
                            <div>{{item.work[0].title}}</div>
                            <!-- <div>20人收藏</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <el-empty v-else description="暂无数据"></el-empty>
        <page v-if="total>12" :page="page" :limit="limit" :total="total"></page>
    </div>
</template>

<script>
import page from '../../components/page.vue'
export default {
    name:'userFans',
    components:{
        page
    },
    data(){
        return{
            list:[],

            // 分页
            total:0,//总数
            page:1,//页数
            limit:12,//每页个数

            is_benren:1
        }
    },
    mounted(){
        this.$parent.tab = 1;
        this.$parent.tabIndex = 3;
        this.is_benren = this.$parent.is_benren;
        this.listFun()
    },
    methods:{
        listFun(){
            var that = this;
            this.$api.POST(this.$face.memberFans,{
                user_id: this.$route.query.userId||'',
                page:this.page,
                limit:this.limit
            },function(res){
                that.list = res.data.list;
                that.total = res.data.total;
                 for (let i in that.list) {
          if (that.list[i].thumb.indexOf("http") == -1) {
            that.list[i].thumb = require("../../assets/images/head.png");
          }
        }
            })
        },

        //关注
        followFun(index,id){
            var that = this;
            var params ={
                id: id,
            }
            this.$api.POST(this.$face.font_followFollow,params,function(res){
                if(that.list[index].is_hg == 1){
                    that.list[index].is_hg = 0
                }else{
                    that.list[index].is_hg = 1
                }
                that.$utile.prompt('success',res.msg);
            })
        },

        // 用户主页
        getOtherUser(id){
            this.$parent.$parent.getUser(id)
        }
    }
}
</script>

<style scoped>
.userFans{
    padding-top: 24px;
    margin: 0 -20px;
    min-height: 400px;
}
.userFans_item{
    width: 320px;
	height: 400px;
	background-color: #ffffff;
	border-radius: 10px;
    margin: 0 20px 34px;
}
.userFans_top{
    width: 100%;
	height: 250px;
	background-color: #fdfdfe;
	border-radius: 10px 10px 0px 0px;
    padding-top: 23px;
    box-sizing: border-box;
    text-align: center;
}
.userFans_top_head{
    width: 68px;
    height: 68px;
    border-radius: 68px;
    margin: 0 auto 13px;
	cursor: pointer;
}

.userFans_top_name{
    font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 16px;
	letter-spacing: 0px;
	color: #333333;
    margin-bottom: 16px;
	cursor: pointer;
}
.userFans_top_dec{
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 12px;
	letter-spacing: 0px;
	color: #666666;
    margin-bottom: 15px;
}
.userFans_top_fans{
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 12px;
	letter-spacing: 0px;
	color: #999999;
    margin-bottom: 20px;
}
.userFans_top_button{
    display: flex;
    align-items: center;
    justify-content: center;
}
.userFans_top_button div{
    margin: 0 6px;
}
.userFans_top_button_home{
    width: 105px;
	height: 40px;
    line-height: 38px;
	background-color: #ffffff;
	border-radius: 10px;
	border: solid 1px #eeeeee;
    box-sizing: border-box;
    font-size: 14px;
	color: #999999;
}
.userFans_top_button_Focus{
    width: 105px;
	height: 40px;
    line-height: 38px;
	background-color: #5957FF;
    border: solid 1px transparent;
	border-radius: 10px;
    font-size: 14px;
	color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.userFans_top_button_Focus img{
    margin-right: 4px;
}
.userFans_top_button_Focus.active{
    background-color: #f6f6f6;
	border-color:#eeeeee;
    color: #999999;
}


.userFans_update{
    padding: 14px 18px 18px;
    box-sizing: border-box;
}
.userFans_update_title{
    font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 14px;
	letter-spacing: 0px;
	color: #333333;
    padding-left: 3px;
    margin-bottom: 21px;
}
.userFans_update_item_img{
   width: 128px;
	height: 85px;
	background-color: #FFF;
	border-radius: 10px;
	border: solid 1px #f6f6f6; 
    box-sizing: border-box;
    overflow: hidden;
}
.userFans_update_item_img img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
}

.userFans_update_item_info{
    margin-left: 14px;
    width: 142px;
}
.userFans_update_item_info div:nth-child(1){
    font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 16px;
	letter-spacing: 0px;
	color: #333333;
    margin-top: 11px;
    margin-bottom: 36px;
}
.userFans_update_item_info div:nth-child(2){
    font-size: 12px;
	font-weight: normal;
	font-stretch: normal;
	line-height: 12px;
	letter-spacing: 0px;
	color: #999999;
}
</style>